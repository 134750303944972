@font-face {
  font-family: 'New Romantics';
  src: url("./assets/fonts/new-romantic.TTF");
}

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
  --main-color: #242629;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #F5F5F7;
  color: var(--main-color);
}

a {
  text-decoration: none;
  color: var(--main-color);
}

ul {
  list-style: none;
}
